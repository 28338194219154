//#region IMPORT

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { LegalModel } from "src/app/models/legal.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { LegalService } from "src/app/services/legal.service";
import { PwaService } from "src/app/services/pwa.service";
import { SessionService } from "src/app/services/session.service";
import { StringConstant } from "src/app/constants/string.constant";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";
import { ENUM_LETTER_ROLE } from "../../constants/enum.constant";


//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-home",
		templateUrl: "./home.component.html",
		styleUrls: ["./home.component.sass"]
	}
)

//#endregion


//#region CLASS

export class HomeComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _booleanNavigation: boolean | null;
	public _booleanNavigationItem: boolean | null;
	public _booleanNavigationItem2: boolean | null;
	public _booleanNavigationEmaterai: boolean | null;
	public _booleanNavigationReimbursement: boolean | null;
	public _booleanNavigationDigitalization: boolean | null;
	public _booleanNavigationApproval: boolean | null;
	public _booleanNavigationRcsa: boolean | null;
	public _booleanNavigationItemRAS: boolean | null;
	public _booleanNavigationRealization: boolean | null;
	public _booleanNavigationItemGCGOnline: boolean | null;
	public _booleanNavigationCodeOfEthics: boolean | null;
	public _booleanNavigationAnnualDisclosure: boolean | null;

	public _enumLetterRole = ENUM_LETTER_ROLE;
	public _stringConstant = StringConstant;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceLegal: LegalService, serviceSession: SessionService, router: Router, public _servicePWA: PwaService)
	{
		super(serviceSession, router);

		this._booleanNavigation = null;
		this._booleanNavigationItem = null;
		this._booleanNavigationItem2 = null;
		this._booleanNavigationEmaterai = null;
		this._booleanNavigationReimbursement = null;
		this._booleanNavigationApproval = null;
		this._booleanNavigationRcsa = null;
		this._booleanNavigationDigitalization = null;
		this._booleanNavigationItemRAS = null;
		this._booleanNavigationRealization = null;
		this._booleanNavigationItemGCGOnline = null;
		this._booleanNavigationCodeOfEthics = null;
		this._booleanNavigationAnnualDisclosure = null;
	}

	//#endregion


	//#region NAVIGATION

	ngOnInit(): void
	{

	}

	//#endregion


	//#region FUNCTION

	toggleNavigation(): void
	{
		this._booleanNavigation = !this._booleanNavigation;
		this._booleanNavigationItem = false;
		this._booleanNavigationEmaterai = false;
		this._booleanNavigationItemGCGOnline = false;
		this._booleanNavigationCodeOfEthics = false;
		this._booleanNavigationAnnualDisclosure = false;
	}

	//#endregion


	//#region WEB SERVICE

	callSignOut(): void
	{
		const componentCurrent: HomeComponent = this;
		const modelUserSession: UserSessionModel | null = this._serviceSession.getUserSession();

		if (modelUserSession != null)
		{
			this._serviceLegal.signOut
			(
				{
					success(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
							{
								componentCurrent.signOut();
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
							}
						});
					},
					fail(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSignOut(); });
					},
					signOut(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
					}
				}, new LegalModel()
			);
		}
		else
		{
			componentCurrent.signOut();
		}
	}

	//#endregion


	//#region NAVIGATION

	goToDashboard(): void
	{
		this._router.navigate(["home", "dashboard", "dashboard"]);
		this.toggleNavigation();
	}

	goToDigitalizationOptimalizationDocumentArchiving(): void
	{
		this._router.navigate(["home", "digitalizationOptimalizationDocumentArchiving", "list"]);
		this.toggleNavigation();
	}

	goToDashboardStamp(): void
	{
		this._router.navigate(["home", "dashboard", "stamp"]);
		this.toggleNavigation();
	}

	goToAbout(): void
	{
		this._router.navigate(["home", "about"]);
		this.toggleNavigation();
	}

	goToTicketReceived(): void
	{
		this._router.navigate(["home", "ticket", "received", "list"]);
		this.toggleNavigation();
	}

	goToTicketSent(): void
	{
		this._router.navigate(["home", "ticket", "sent", "list"]);
		this.toggleNavigation();
	}

	goToTicketReport(): void
	{
		this._router.navigate(["home", "ticket", "report"]);
		this.toggleNavigation();
	}

	goToTicketSatisfactionReport(): void
	{
		this._router.navigate(["home", "ticket", "satisfactionreport"]);
		this.toggleNavigation();
	}

	goToEmaterai(): void
	{
		this._router.navigate(["home", "ematerai", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForMaker(): void
	{
		this._router.navigate(["home", "ematerai", "maker", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForChecker(): void
	{
		this._router.navigate(["home", "ematerai", "checker", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForSigner(): void
	{
		this._router.navigate(["home", "ematerai", "signer", "list"]);
		this.toggleNavigation();
	}

	goToReimbursementGeneralChecker(): void
	{
		this._router.navigate(["home", "reimbursement" ,"approval", "checker", "list"]);
		this.toggleNavigation();
	}

	goToReimbursementGeneralSigner(): void
	{
		this._router.navigate(["home", "reimbursement", "approval", "signer", "list"]);
		this.toggleNavigation();
	}

	goToAssessment(): void
	{
		this._router.navigate(["home", "rcsa", "assessment", "list"]);
		this.toggleNavigation();
	}

	goToApproval(): void
	{
		this._router.navigate(["home", "rcsa", "approval", "list"]);
		this.toggleNavigation();
	}

	goToRscaReport(): void
	{
		this._router.navigate(["home", "rcsa", "report", "list"]);
		this.toggleNavigation();
	}

	goToRiskIssueRequest(): void
	{
		this._router.navigate(["home", "rcsa", "riskissuerequest", "list"]);
		this.toggleNavigation();
	}

	goToRASDashboard(): void
	{
		this._router.navigate(["home", "ras", "dashboard", "list"]);
		this.toggleNavigation();
	}

	goToRASRealization(): void
	{
		this._router.navigate(["home", "ras", "realization", "list"]);
		this.toggleNavigation();
	}

	goToRASMonitoring(): void
	{
		this._router.navigate(["home", "ras", "realization", "monitoring", "list"]);
		this.toggleNavigation();
	}

	goToRASRealizationApproval(): void
	{
		this._router.navigate(["home", "ras", "realization", "approval", "list"]);
		this.toggleNavigation();
	}

	goToRASFollowUpPlan(): void
	{
		this._router.navigate(["home", "ras", "followupplan", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineProfile(): void
	{
		this._router.navigate(["home", "gcgonline", "profile"]);
		this.toggleNavigation();
	}

	goToGCGOnlineGratification(): void
	{
		this._router.navigate(["home", "gcgonline", "gratification", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineIntegrityPact(): void
	{
		this._router.navigate(["home", "gcgonline", "integritypact", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineCodeOfEthics(): void
	{
		this._router.navigate(["home", "gcgonline", "codeofethics", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineCodeOfEthicsApproval(): void
	{
		if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGCHECKER))
		{
			this._router.navigate(["home", "gcgonline", "codeofethics", "checker", "list"]);
			this.toggleNavigation();
		}
		else if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGSIGNER))
		{
			this._router.navigate(["home", "gcgonline", "codeofethics", "signer", "list"]);
			this.toggleNavigation();
		}
		else
		{
		}
	}

	goToGCGOnlineAnnualDisclosure(): void
	{
		this._router.navigate(["home", "gcgonline", "annualdisclosure", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineAnnualDisclosureApproval(): void
	{
		if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGCHECKER))
		{
			this._router.navigate(["home", "gcgonline", "annualdisclosure", "checker", "list"]);
			this.toggleNavigation();
		}
		else if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGSIGNER))
		{
			this._router.navigate(["home", "gcgonline", "annualdisclosure", "signer", "list"]);
			this.toggleNavigation();
		}
		else
		{
		}
	}

	//#endregion


}

//#endregion